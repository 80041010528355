<template>
  <div class="login-container">
    <validation-observer ref="observer">
      <v-form
        class="login-form"
        @submit.prevent="authMethod"
      >
        <h2 class="login__title">
          Welcome to Timeprism
        </h2>
        <validation-provider
          v-slot="{ errors }"
          name="email"
          rules="required|email|max:255"
        >
          <text-field
            v-model="formData.email"
            class="mb-3"
            outlined
            placeholder="Email address"
            type="email"
            :error-messages="errors"
          />
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="password"
          rules="required|max:255"
        >
          <text-field
            v-model="formData.password"
            outlined
            placeholder="Password"
            :append-icon="passwordType ? 'visibility' : 'visibility_off'"
            :type="!passwordType ? 'password' : 'text'"
            :error-messages="errors"
            @click:append="passwordType = !passwordType"
          />
        </validation-provider>
        <v-checkbox
          v-model="formData.remember"
          class="mt-0 mb-1"
          label="Stay logged in"
        />
        <primary-button
          class="mb-5"
          full-width
          :loading="loading"
          type="submit"
        >
          Sign In
        </primary-button>
        <span v-if="errorMessage" class="error-message">
          {{ errorMessage }}
        </span>
        <router-link to="/password-recover" class="recover-link">
          Forgot Password?
        </router-link>
      </v-form>
    </validation-observer>

    <v-divider />
    <div class="text-center py-6">
      Aren’t registered yet?
      <span class="text-primary">
        Sign Up
      </span>
    </div>
  </div>
</template>

<script>
import TextField from '@/components/ui/TextField/TextField';
import PrimaryButton from '@/components/ui/Buttons/PrimaryButton';

export default {
  name: 'Login',
  components: { PrimaryButton, TextField },
  data() {
    return {
      loading: false,
      passwordType: false,
      formData: {
        email: '',
        password: '',
        remember: false,
      },
    };
  },
  computed: {
    errorMessage() {
      return this.$store.getters.getErrorMsg;
    },
  },
  created() {
    this.$store.commit('updateErrorMsg', null);
    this.$store.commit('SET_PERMISSIONS', {});
  },
  methods: {
    authMethod() {
      const observerRef = this.$refs.observer;

      observerRef.validate().then((isValid) => {
        if (isValid) {
          this.loading = true;
          this.$store.dispatch('AUTH_CALL', { ...this.formData })
            .then(() => {
              this.$router.push('/dashboard');
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .input-group {
    max-width: unset;
  }
  .error-message {
    text-align: center;
    display: block;
    color: $error;
    margin-bottom: 20px;
  }
</style>
